//党员列表
const partyMemberUrl = `/gateway/hc-govern/manageapi/cp/cpList`;
//党员信息
const partyMemberDetailUrl = `/gateway/hc-govern/manageapi/cp/cpInfo`;
//党员编辑
const editPartyMemberUrl = `/gateway/hc-govern/manageapi/cp/cpEdit`;
//党员编辑
const deletePartyMemberUrl = `/gateway/hc-govern/manageapi/cp/cpDelete`;
//编辑手机号
const cpPhoneEdit = `/gateway/hc-govern/manageapi/cp/cpPhoneEdit`;
export {
  partyMemberUrl,
  partyMemberDetailUrl,
  editPartyMemberUrl,
  deletePartyMemberUrl,
  cpPhoneEdit
};
